import i18next, { t } from 'i18next';

import styles from './Header.module.scss';

import useWindowScrolledFlag from '#hooks/useWindowScrolledFlag';
import Hamburger from '#components/menu/Hamburger/index';
import LanguageSelector from '#components/menu/LanguageSelector/index';
import Navigation from '#components/menu/Navigation/index';
import Button from '#components/ui/Button/index';
import { initI18Next } from '#src/utils/I18nextInit';
import { prepareUrl } from '#utils/prepareUrl';
import LogoLink from '#components/common/LogoLink';

type Props = {
  templateType: string;
  showLanguageSelector: boolean;
  currentLanguage: string;
  logoLink?: string;
  pathname: string;
};

const Header = ({
  templateType,
  showLanguageSelector,
  currentLanguage,
  pathname,
}: Props) => {
  const isScrolled = useWindowScrolledFlag();
  const isDarkHeader = templateType.includes('dark');

  initI18Next(i18next, currentLanguage);

  return (
    <header
      id="header"
      className={`${styles.header} ${isScrolled && styles.isScrolled} ${isDarkHeader && styles.dark}`}
    >
      <div className={styles.container}>
        <LogoLink style={styles.logo} currentLanguage={currentLanguage} templateType={templateType} />
        <Navigation headerStyle={templateType} pathname={pathname} />
        {showLanguageSelector && (
          <LanguageSelector lang={currentLanguage} headerStyle={templateType} />
        )}
        <Hamburger headerStyle={templateType} />
        <Button
          text={t('buttons.login')}
          styleType={isDarkHeader ? 'dark' : 'light'}
          onClick={() => (window.open(prepareUrl('/login', currentLanguage) || '/login'), '_blank')}
        />
      </div>
    </header>
  );
};

export default Header;
