import i18next, { t } from 'i18next';

import styles from '#components/menu/Navigation/Navigation.module.scss';

import Button from '#components/ui/Button/index';

import { prepareUrl } from '#utils/prepareUrl';
import FeaturesSelectorList from '#components/menu/Navigation/FeaturesSelectorList';
import { useStore } from '@nanostores/react';
import { openHamburger, openFeatures } from '#store/headerNavigation';
import useResponsive from '#hooks/useResponsive';
import { scrollToIdElement } from '#utils/scroll';

type Props = {
  headerStyle: string;
  pathname: string;
};

const Navigation = ({ headerStyle, pathname }: Props) => {
  const lang = i18next.language;
  const preparedLink = prepareUrl('/login', lang);
  const preparedLinkMob = prepareUrl('/email', lang);
  const $openHamburger = useStore(openHamburger);
  const $openFeatures = useStore(openFeatures);
  const isDesktop = useResponsive('desktop');
  const otherPages = ['terms', 'policy', '404', 'check-lost-phone'];

  const toggleFeatures = (status: boolean) => {
    openFeatures.set(status);
  };

  const handleCloseHamburger = (element: string) => {
    otherPages.forEach((el) => {
      if (pathname.includes(el)) {
        window.location.assign(prepareUrl(`/#${element}`, lang, false));
      } else {
        scrollToIdElement(element);
      }
    });
    toggleFeatures(false);
    openHamburger.set(false);
  };

  return (
    <ul
      className={`${styles.navigation} ${($openHamburger || $openFeatures) && styles.isOpened} ${headerStyle === 'dark' && styles.dark}`}
    >
      <li className={styles.list}>
        <span className={styles.link} onClick={() => handleCloseHamburger('how-it-works')}>
          {t('header.how')}
        </span>
      </li>

      <li
        className={`${styles.list} ${styles.features} ${$openFeatures && styles.isOpened}`}
        onMouseEnter={() => isDesktop && toggleFeatures(true)}
        onMouseLeave={() => isDesktop && toggleFeatures(false)}
      >
        <span
          className={`${styles.link} ${styles.features}`}
          role="presentation"
          onClick={() => toggleFeatures(!$openFeatures)}
        >
          {t('header.features')}
        </span>
        {$openFeatures && <FeaturesSelectorList lang={lang} />}
      </li>

      <li className={styles.list}>
        <span className={styles.link} onClick={() => handleCloseHamburger('questions')}>
          {t('header.faq')}
        </span>
      </li>

      <li className={styles.list}>
        <a href={`${t('footer.blogLink')}${t('rootAlias')}`} className={styles.link}>
          {t('header.blog')}
        </a>
      </li>

      <li className={styles.list}>
        <Button
          text={t('buttons.login')}
          styleType={headerStyle.includes('dark') ? 'darkMob' : 'lightMob'}
          onClick={() => window.open(preparedLink, '_blank')}
        />
      </li>

      <li className={styles.list}>
        <Button
          text={t('buttons.locateNow')}
          styleType="orangeMob"
          onClick={() => (window.location.href = preparedLinkMob)}
        />
      </li>
    </ul>
  );
};

export default Navigation;
